.box {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  @media(max-width: $mobile) {
    height: 100vh;
    border-radius: 0;
    max-width: 100% !important;
  }
  &.has-footer {
    justify-content: center;
  }
}

.box .box-footer {
  display: inherit;
  justify-content: flex-end;
  padding: 1.6rem 0;

  &.is-space-between {
    justify-content: space-between;
  }

  &.has-content-centered {
    justify-content: center;
  }
}

.box.is-bare {
  box-shadow: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  color: initial;
  padding: 0;
}


.box.is-fullheight {
  height: 100%;

  .box-body {
    flex: 1;

  }
}
