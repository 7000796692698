@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900');

//Styleguide overrides and extra modifiers
@import 'mixins';
@import 'button';
@import 'notification';
@import 'box';
@import 'dropdown';
@import 'form';
@import 'image';
@import 'menu';
@import 'icon';
@import 'title';
@import 'level';
@import 'media';
@import 'pagination';

//Project custom styles and components
@import 'analysis';
@import 'appbar';
@import 'assistant';
@import 'space';
@import 'splash-screen';
@import 'loading-container';
@import 'body-container';
@import 'layout';
@import 'onboarding';
@import 'text';
@import 'switch';
@import 'radio-group';
@import 'context-menu';
@import 'datatable';
@import 'link';
@import 'checkbox';
@import 'card-select';
@import 'register-form-modal';
@import 'form-grid';
@import 'confirmation-message';
@import 'section-group';
@import 'contract-editor';
@import 'editor-field';

// documents
@import 'base';
@import 'documents';

//Vendors custom styles
@import 'swiper';
@import 'company-card';

.is-padded {
  @media (max-width: $desktop) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
