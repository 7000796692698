$pagination-color: $grey;
$pagination-border-color: $grey-lighter;
$pagination-margin: -0.25rem;

$pagination-hover-color: $primary;
$pagination-hover-border-color: $link-hover-border;

$pagination-focus-color: $primary;
$pagination-focus-border-color: $link-focus-border;

$pagination-active-color: $primary;
$pagination-active-border-color: $link-active-border;

$pagination-disabled-color: $grey;
$pagination-disabled-background-color: $grey-lighter;
$pagination-disabled-border-color: $grey-lighter;

$pagination-current-color: $primary;
$pagination-current-background-color: transparent;
$pagination-current-border-color: transparent;

$pagination-ellipsis-color: $grey-light;

$pagination-shadow-inset: inset 0 1px 2px rgba($black, 0.2);
