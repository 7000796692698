.notification.is-fixed {
  position: fixed;
  z-index: 2147483647;
  top: -300%;
  right: -100%;

  box-shadow: 0 2px 3px rgba($black, 0.1);

  font-size: 14px;
  font-weight: 500;

  padding: .8rem 2.4rem .8rem 1rem;

  .delete {
    background: none;
  }
}

.notification.is-fixed.is-open {
  // top: 0;
  // right: 0;
  animation: notificationShowTopRight .2s forwards;
}


.notification.is-fixed-bottom {
  position: fixed;
  z-index: 2147483647;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  border-radius: 0;
}

.notification.is-fixed.is-invisible {
  visibility: visible !important;
}

.notification.is-fixed.is-fixed-top-left:not(.is-invisible) {
  animation: notificationShowTopLeft .2s forwards;
}

.notification.is-fixed.is-fixed-top-center:not(.is-invisible) {
  animation: notificationShowTopCenter .2s forwards;
}

.notification.is-fixed.is-fixed-top-right:not(.is-invisible) {
  animation: notificationShowTopRight .2s forwards;
}

@keyframes notificationShowTopLeft {
  0% {
    opacity: 0;
    top: 5px;
    left: 5px;
    transform: translate(-100%, 0);
  }
  100% {
    opacity: 1;
    top: 5px;
    left: 5px;
    transform: translate(0, 0);
  }
}

@keyframes notificationShowTopCenter {
  0% {
    opacity: 0;
    top: 5px;
    right: 50%;
    transform: translate(50%, -100%);
  }
  100% {
    opacity: 1;
    top: 5px;
    right: 50%;
    transform: translate(50%, 0);
  }
}

@keyframes notificationShowTopRight {
  0% {
    opacity: 0;
    top: 5px;
    right: 5px;
    transform: translate(100%, 0);
  }
  100% {
    opacity: 1;
    top: 5px;
    right: 5px;
    transform: translate(0, 0);
  }
}
