.container-rfmodal {
  height: calc(100% - 60px);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  background-color: #F2F2F2;
  overflow: scroll;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.header-rfmodal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 10px;
}

.leftWrap-rfmodal {
  display: flex;
  align-items: center;
}

.rightWrap-rfmodal {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-right: 10px;
}

.header-title-rfmodal {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  &.noTitle {
    display: none;
  }
  @media (min-width: 768px) {
    font-size: 14px;
  }
}

.return-rfmodal {
  color: #4a4a4a;
  margin-right: 10px;
}

.back-button {
  border-width: 0px;
  background-color: transparent;
  cursor: pointer;
}



