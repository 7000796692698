.space {
  height: 1.5rem;
  margin: 0 !important;
}

.is-xxxsmall.is-vertical {
  height: 2px;
  margin: 0 !important;
}

.is-xxsmall.is-vertical {
  height: 3px;
  margin: 0 !important;
}

.is-xsmall.is-vertical {
  height: 5px;
  margin: 0 !important;
}

.is-small.is-vertical {
  height: 1rem;
  margin: 0 !important;
}

.is-medium.is-vertical {
  height: 2rem;
  margin: 0 !important;
}

.is-large.is-vertical {
  height: 40px;
  margin: 0 !important;
  @media(max-width: $tablet) {
    height: 20px;
  }
}

.is-xlarge.is-vertical {
  height: 60px;
  margin: 0 !important;
}

.is-xxlarge.is-vertical {
  height: 80px;
  margin: 0 !important;
}

.is-xxxlarge.is-vertical {
  height: 100px;
  margin: 0 !important;
}
