.body-container {
  width: 100%;
  max-height: 100vh;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
	padding: 10px 20px;
  @media(min-width: 720px) {
		padding: 1rem 2rem 4rem;
  }
}

.body-container.is-vh-centered {
  justify-content: center;
  align-items: center;
}

.body-container.is-padded {
  padding: 2rem;
}
