.onboarding {
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    &.is-active {
        display: flex;
    }
}

.onboarding-container {
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 4px rgba(#000, 0.2);
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 650px;
    padding: 0rem 2rem;
    background-color: $white;
    border-radius: 5px;
    .swiper-container {
        width: 100%;
        height: 100%;
        padding: 42px 0;
    }
}

.onboarding-step-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboarding-background {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(rgb(54, 54, 54), 0.7);
}