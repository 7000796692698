@import "../../../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../../../node_modules/bulma/sass/utilities/functions";

@import "../../../../node_modules/bulma/sass/utilities/derived-variables";


// Brand colors

$brand-color-2: #16A1DF;
$brand-color-dark-2: #118AC0;
$brand-color-light-2: #86D0F1;

$brand-color-3: #16A1DF;
$brand-color-dark-3: #31C3C0;
$brand-color-light-3: #7CEBE9;

$brand-color-yellow: #FBD03E;
$brand-color-red: #F75C40;
$brand-color-green: #3ECE8A;


$brand-color-black: #4F4F4F;
$brand-color-grey: #7F8996;
$brand-color-grey-light: #C5C6C9;

$dark-blue: #5f6775;


// Custom colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);

$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);

$github: #333;
$github-invert: findColorInvert($github);

$addColors: (
  "twitter":($twitter, $twitter-invert),
  "linkedin": ($linkedin, $linkedin-invert),
  "github": ($github, $github-invert)
);

$colors: map-merge($colors, $addColors);

// Control

$control-radius: $radius;
$control-radius-small: $radius-small;

$control-padding-vertical: calc(0.375em - 1px);
$control-padding-horizontal: calc(0.625em - 1px);

// Colors



$primary: rgb(43, 113, 235);
$primary-dark: #2266D7;
$primary-light: #6FAFFF;

// $primary: #7E0021;
// $primary-dark: rgb(90, 34, 49);
// $primary-light: rgb(156, 87, 110);

$primary-izee: rgb(38, 103, 216);
$primary-dark-izee: #2266D7;
$primary-light-izee: #6FAFFF;

$featured: $success;


$info: $cyan;
$success: #39c357;
$warning: #efc63b;
$danger: #EB144C;

$light: $white-ter;
$dark: $grey-darker;

// Invert colors

$orange-invert: findColorInvert($orange);
$yellow-invert: findColorInvert($yellow);
$green-invert: findColorInvert($green);
$turquoise-invert: findColorInvert($turquoise);
$cyan-invert: findColorInvert($cyan);
$blue-invert: findColorInvert($blue);
$purple-invert: findColorInvert($purple);
$red-invert: findColorInvert($red);

$primary-invert: $turquoise-invert;
$info-invert: $cyan-invert;
$success-invert: $green-invert;
$warning-invert: $yellow-invert;
$danger-invert: $red-invert;
$light-invert: $dark;
$dark-invert: $light;

// General colors

$background: $white-ter;

$border: $grey-lighter;
$border-hover: $grey-light;

// Text colors

$text: $grey-dark;
$text-invert: findColorInvert($text);
$text-light: $grey;
$text-strong: $grey-darker;

// Code colors

$code: $red;
$code-background: $background;

$pre: $text;
$pre-background: $background;

// Link colors

$link: $blue;
$link-invert: $blue-invert;
$link-visited: $purple;

$link-hover: $white;
$link-hover-border: $primary-light;

$link-focus: $white;
$link-focus-border: $primary-light;

$link-active: $white;
$link-active-border: $primary-light;

// Typography

$family-primary: $family-sans-serif;
$family-code: $family-monospace;

$size-small: $size-7;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;

// Lists and maps

$colors: ("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert), "link": ($link, $link-invert), "info": ($info, $info-invert), "success": ($success, $success-invert), "warning": ($warning, $warning-invert), "danger": ($danger, $danger-invert));
$shades: ("black-bis": $black-bis, "black-ter": $black-ter, "grey-darker": $grey-darker, "grey-dark": $grey-dark, "grey": $grey, "grey-light": $grey-light, "grey-lighter": $grey-lighter, "white-ter": $white-ter, "white-bis": $white-bis);

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;


// Colors

$black:        hsl(0, 0%, 4%);
$black-bis:    hsl(0, 0%, 7%);
$black-ter:    hsl(0, 0%, 14%);

$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 48%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter:    hsl(0, 0%, 96%);
$white-bis:    hsl(0, 0%, 98%);
$white:        hsl(0, 0%, 100%);

$orange:       hsl(14,  100%, 53%);
$yellow:       hsl(50, 100%, 41%);
$green:        hsl(141, 71%,  48%);
$turquoise:    hsl(171, 100%, 41%);
$cyan:         hsl(204, 86%,  53%);
$blue:         hsl(217, 71%,  53%);
$purple:       hsl(261, 67%, 52%);
$red:          hsl(348, 100%, 61%);

// Typography

$family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

// Responsiveness

$mobile: 425px;
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap);
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);

// Miscellaneous

$easing: ease-out;
$radius-small: 2px;
$radius: 4px;
$radius-large: 10px;
$radius-rounded: 290486px;
$speed: 86ms;

// Flags

$variable-columns: true;
