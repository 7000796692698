.link {
  @include button-reset;
  color: $primary;
  font-size: 12px;
  display: inline;
  &.is-center {
    width: 100%;
  }
  
  &:hover {
    color: $primary-dark;
  }

}

