.docsInput {
    @include w(100%);
    @include fx(center, flex-end);
    @include jc(space-between);
    @include mb(20px);
    .field {
        @include w(100%);
        @include m(0px);
        @include mr(20px);
    }
}

.docsTable {
    .datatable-head {
        @include bg(transparent);
        .datatable-cell {
            @include fc($c-g1);
            @include fs(13px);
            @include bb(1px solid $c-g6);
            @include px(10px);
        }
    }
    .datatable-row {
        .datatable-cell {
            @include bl(0px);
            // @include px(10px);
            // @include py(5px);
        }
    }
    .button {
        @include fx(center, center);
        @include jc(space-between);
        span {
            @include fs(13px);
        }
        &.SavedButton {
            @include w(100%);
            @include bg(#39c357);
        }
        &.SubmitButton {
            @include w(100%);
            @include fc(#808996);
            @include bg(#f2f6fa);
        }
    }
    .check-icon {
        @include fs(20px);
        @include mx(5px);
        cursor: pointer;
    }
}

.ActionsBar {
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include w(90%);
        @include mt(20px);
        @include jc(space-between);
        .Item {
            button,
            .icon {
                @include fc($c-g2);
            }
            button {
                &.disabled {
                    @include fc($c-g6);
                    .icon {
                        @include fc($c-g6);
                    }
                }
                &[disabled]:hover,
                &.disabled:hover:before {
                    @include bg($c-g8 !important);
                    @include fc($c-g2);
                }
            }
        }
    }
}

.FullDocsModule {
    @include fx(row, center);
    @include jc(center);
    @include h(100%);
    .LeftPanel {
        @include w(360px);
        @include h(100%);
        @include bg($c-g8);
        overflow: scroll;
        overflow-x: hidden;
        .header {
            @include w(100%);
            @include h(110px);
            @include p(30px);
            @include bg(#2091EA);
        }
        .document_name {
            @include fc($c-g8);
            @include fw(bold);
        }
        .status {
            @include fc($c-g8);
            @include fs(13px);
            @include ml(-5px);
        }
        .buttons {
            @include fx(row, center);
            @include jc(space-between);
            @include px(30px);
            @include py(20px);
            @include bg(#F2F6FA);
            .Item {
                @include bg(#F2F6FA);
                .button {
                    @include py(0px);
                    @include mb(0px);
                    @include r(2px);
                    @include fc($c-g4);
                    @include b(1px solid #D5DADF);
                }
            }
        }
        .proposal-section {
            @include p(30px);
            .proposal-section-title {
                @include bb(1px solid #D5DADF);
                @include pb(5px);
                @include mb(20px);
            }
            .documentView-container-info {
                @include mb(30px);
                .documentView-title {
                    @include fs(14px);
                    @include fc(#363636);
                }
                .doc-data {
                    @include fs(14px);
                    @include fc(#5F6776);
                }
            }
        }
    }
    .RightPanel {
        @include w(calc(100% - 360px));
        @include h(100%);
        @include bg($c-g5);
    }
}

.close_modal {
    position: absolute;
    cursor: pointer;
    left: 320px;
    top: 20px;
    @include fc($c-g8);
}

.fullscreen-modal {
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    z-index: 200;
    transition: all 0s ease-in-out 0.4s, opacity 0.4s ease-in-out 0s;
    &.is-active {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 0s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
        .fullscreen-modal-background {
            opacity: 0.6;
        }
        .fullscreen-modal-content {
            opacity: 1;
            animation: slideUpSoft 0.4s ease-in-out;
        }
    }
}

.fullscreen-modal-content {
    width: 100%;
    height: 100%;
    // max-width: 640px;
    max-height: 100%;
    position: absolute;
    z-index: 100;
    opacity: 0;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    background-color: #ffffff;
    animation: fadeOut 0.4s ease-in-out;
}

.fullscreen-modal-body {
    flex: 1;
    overflow-y: auto;
    .columns {
        height: 100%;
    }
    .actions {
        @include w(100%);
        @include fx(row, center);
        @include jc(center);
        position: relative;
        top: -100px;
        .wrap {
            @include w(400px);
            @include fx(row, center);
            @include jc(space-evenly);
            @include bg($c-g1);
            @include p(15px);
            @include b(1px solid $c-g4);
            @include fc($c-g4);
            @include r(3px);
        }
    }
    .main {
        height: 100%;
        width: 100%;
        background-color: rgba(#000, 0.8);
        transition: all 0.2s ease-in-out;
        display: flex;
        .image {
            height: 100%;
            width: 80%;
            .pg-viewer-wrapper {
                overflow: hidden;
            }
        }
        .arrow_left,
        .arrow_right {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                font-size: 2.5rem;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

.actions-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.actions-title {
    font-size: 22px;
    color: #363636;
    margin-bottom: 1rem;
}

.document-name {
    font-size: 16px;
    color: #5F6776;
}

.document-name-container {
    margin-bottom: 1.5rem;
}

.email_title {
    font-size: 22px;
    color: #363636;
    margin-bottom: 20px;
}

.docs_name {
    font-size: 16px;
    color: #5F6776;
    margin-bottom: 10px;
}

.message_box {
    margin-top: 20px;
}

.fullscreen-modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    background-color: rgba(#000, 0.8);
    transition: all 0.2s ease-in-out;
    display: block;
}

.mdocument-container {
    padding-top: 20px;
    padding-bottom: 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #EAEAEA;
}

.mdocument-title {
    font-size: 16px;
    font-weight: bold;
}
