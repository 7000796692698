$title-color: $grey-darker;
$title-size: $size-3;
$title-weight: $weight-semibold;
$title-strong-color: inherit;
$title-strong-weight: inherit;
$title-sub-size: 0.75em;
$title-sup-size: 0.75em;

$subtitle-color: $grey-dark;
$subtitle-size: $size-5;
$subtitle-weight: $weight-normal;
$subtitle-strong-color: $grey-darker;
$subtitle-strong-weight: $weight-semibold;
