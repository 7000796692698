.contract-editor {
  .contract-type-options {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    height: 100%;

    button {
      margin: 0;
    }
  }

  .editor-wrapper {
    overflow: auto;
  }

  .token-list-wrapper {
    overflow-y: scroll;
  }

  .token-list-wrapper .is-active,
  .token-list-wrapper :hover {
    color: black;
    text-decoration: none;
  }

  .token-list-wrapper .is-active {
    font-weight: bold;
  }

  .token-list-wrapper a.panel-block {
    color: black;
  }

  .has-pagination .checkbox {
    flex: 1;
    padding-top: 37px;
  }

  .editor-wrapper,
  .token-list-wrapper {
    position: relative;
    height: calc(100vh - 355px);
    min-height: 200px;
  }

  .quill {
    position: relative;
    height: calc(100% - 44px); // full size minus toolbar
  }

  .ql-toolbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .ql-snow {
    .ql-picker {
      &.ql-size {
        .ql-picker-label,
        .ql-picker-item {
          &::before {
            content: attr(data-value) !important;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
}
