.image {
  flex-shrink: 0;
}

.image.is-avatar img {
  border-radius: 50%;
}

.image.has-background {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
  img {
    width: 0;
    height: 0;
  }
}

