$panel-item-border: 1px solid $border;

$panel-heading-background-color: $background;
$panel-heading-color: $text-strong;
$panel-heading-line-height: 1.25;
$panel-heading-padding: 0.5em 0.75em;
$panel-heading-radius: $radius;
$panel-heading-size: 1.25em;
$panel-heading-weight: $weight-light;

$panel-tab-border-bottom: 1px solid $border;
$panel-tab-active-border-bottom-color: $link-active-border;
$panel-tab-active-color: $link-active;

$panel-list-item-color: $text;
$panel-list-item-hover-color: $link;

$panel-block-color: $text-strong;
$panel-block-hover-background-color: $background;
$panel-block-active-border-left-color: $link;
$panel-block-active-color: $link-active;
$panel-block-active-icon-color: $link;

$panel-icon-color: $text-light;
