/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  height: 25px;
  padding-left: 52px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.switch + .switch {
  margin-top: .5rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

/* The slider */
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark-blue, 0);
  border: 1px solid #b5b5b5;
  -webkit-transition: .2s;
  transition: .2s;
  width: 52px;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  background-color: $dark-blue;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked ~ .switch-slider {
  background-color: $dark-blue;
  border: 1px solid $dark-blue;
}

input:focus ~ .switch-slider {
  border: 1px solid inherit;
  box-shadow: none;
}

input:checked ~ .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: $white;
}

/* Rounded switch-sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}

.is-disabled {
  .switch-slider {
    opacity: .4;
  }
}

.switch-description {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  color: #7F8996;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
