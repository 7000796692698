
$button-color: $white;
$button-background-color: $primary;
$button-border-color: $primary-light;

$button-hover-color: $link-hover;
$button-hover-border-color: $link-hover-border;

$button-focus-color: $link-focus;
$button-focus-border-color: $link-focus-border;
$button-focus-box-shadow-size: 0 0 0 0.125em;
$button-focus-box-shadow-color: rgba($link, 0.25);

$button-active-color: $link-active;
$button-active-border-color: $link-active-border;

$button-text-color: $text;
$button-text-hover-background-color: $primary-dark;
$button-text-hover-color: $text-strong;

$button-disabled-background-color: $primary-light;
$button-disabled-border-color: $primary-light;
$button-disabled-shadow: none;
$button-disabled-opacity: 0.5;

$button-static-color: $white;
$button-static-background-color: $primary-light;
$button-static-border-color: $primary-light;
