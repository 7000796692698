@import "base";

.section {
	&.group-white {
		@include bg(rgba(255,255,255,1));
		@include mb(20px);
		@include px(10px);
		@media(min-width: 720px) {
			@include px(20px);
		}
	}
	&.no-py {
		@include py(0px);
	}
}
.section-group {
	@include w(100%);
}
.section-item {
	@include w(100%);
	@include bg(rgba(0,0,0,0));
	.si-header {
		@include bb(2px solid rgba(0,0,0,0.1));
		@include fx(row, flex-start);
		@include jc(space-between);
		@include py(20px);
		cursor: pointer;
		.si-item-name {
			@include fc(rgba(0,0,0,0.8));
			@include fg(2);
			@include fs(16px);
			@include fw(bold);
			@include px(10px);
		}
		.si-item-icon,
		.si-item-flag {
			@include fc(rgba(0,0,0,0.3));
			@include fs(16px);
			@include px(10px);
			@include w(38px);
		}
	}
	.si-contain {
		display: none;
		@include bg(rgba(0,0,0,0.02));
		@include px(20px);
		@include py(20px);
		.columns {
			@include w(100%);
		}
		@media(min-width: 720px) {
			@include px(48px);
		}
	}
	&.open-true {
		.si-header {}
		.si-contain {
			display: flex;
		}
	}
	&.evaluation-status {
		.si-item-flag {
			@include w(238px);
			@include fx(row, center);
			@include jc(flex-end);
			i {
				@include mr(8px);
				@include ml(4px);
			}
		}
	}
}

.print-wrap {
	@include px(0px);
	@media(min-width: 720px) {
		@include px(20px);
	}
}
