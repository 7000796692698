$content-heading-color: $text-strong;
$content-heading-weight: $weight-normal;
$content-heading-line-height: 1.125;

$content-blockquote-background-color: $background;
$content-blockquote-border-left: 5px solid $border;
$content-blockquote-padding: 1.25em 1.5em;

$content-pre-padding: 1.25em 1.5em;

$content-table-cell-border: 1px solid $border;
$content-table-cell-border-width: 0 0 1px;
$content-table-cell-padding: 0.5em 0.75em;
$content-table-cell-heading-color: $text-strong;
$content-table-row-hover-background-color: $background;
$content-table-head-cell-border-width: 0 0 2px;
$content-table-head-cell-color: $text-strong;
$content-table-foot-cell-border-width: 2px 0 0;
$content-table-foot-cell-color: $text-strong;
