.dropdown-trigger {
  cursor: pointer;
  // > * {
  //   vertical-align: middle;
  //   display: inline-block;
  //   + * {
  //     margin-left: .5rem;
  //   }
  // }
  // .icon {
  //   color: $white;
  //   font-size: 10px;
  // }
}

.dropdown-item {
  // @include button-reset;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  // display: block;
  // width: 100%;
  // text-align: left;
  // display: flex;
  // align-items: center;

  // span + span {
  //   margin-left: .5rem;
  // }
}

// button.dropdown-item:hover,
// a.dropdown-item:hover {
//   background-color: $white-ter;

// }

.dropdown-content {
  min-width: 280px;
  max-width: 280px;
}

.dropdown-item.is-featured {
  padding-right: 0;
  padding-left: 0;
  // margin-top: -14px;
}

.flexbar:not(.is-secondary) .flexbar-child {

  .dropdown-menu {
    transition: .3s;

    @media(max-width: $tablet) {
      display: block !important;
      position: fixed;
      top: 66px;
      right: -100% !important;
    }
  }

  .dropdown.is-active {
    .dropdown-menu {
      @media(max-width: $tablet) {
        right: 4px !important;
      }
    }
  }
}
