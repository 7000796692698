$input-color: $grey-darker;
$input-background-color: $white;
$input-border-color: $grey-lighter;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);

$input-hover-color: $grey-darker;
$input-hover-border-color: $grey-light;

$input-focus-color: $grey-darker;
$input-focus-border-color: $link;
$input-focus-box-shadow-size: 0 0 0 0.125em;
$input-focus-box-shadow-color: rgba($link, 0.25);

$input-disabled-color: $text-light;
$input-disabled-background-color: $background;
$input-disabled-border-color: $background;

$input-arrow: $link;

$input-icon-color: $grey-lighter;
$input-icon-active-color: $grey;

$input-radius: $radius;

$file-border-color: $border;
$file-radius: $radius;

$file-cta-background-color: $white-ter;
$file-cta-color: $grey-dark;
$file-cta-hover-color: $grey-darker;
$file-cta-active-color: $grey-darker;

$file-name-border-color: $border;
$file-name-border-style: solid;
$file-name-border-width: 1px 1px 1px 0;
$file-name-max-width: 16em;

$label-color: $brand-color-grey;
$label-weight: $weight-normal;

$help-size: $size-small;
