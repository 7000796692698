

$level-color-1: $orange;
$level-color-2: $green;
$level-color-3: $blue;
$level-color-4: $yellow;
$level-color-5: $purple;
$level-color-6: $grey;
$level-color-7: $turquoise;


.level {

}

.level-item {
  flex-direction: column;
  border: 1px solid $border;

  > div {
    padding: .5rem .8rem;
    width: 100%;
  }

  .heading {
    text-align: left;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    color: $grey-light;
  }

  .level-info {
    width: 100%;
    display: block;
    text-align: left;
    font-weight: 700;
  }
  
  // &:nth-child(7n+1) { .level-info { color: $level-color-1; } }
  // &:nth-child(7n+2) { .level-info { color: $level-color-2; } }
  // &:nth-child(7n+3) { .level-info { color: $level-color-3; } }
  // &:nth-child(7n+4) { .level-info { color: $level-color-4; } }
  // &:nth-child(7n+5) { .level-info { color: $level-color-5; } }
  // &:nth-child(7n+6) { .level-info { color: $level-color-6; } }
  // &:nth-child(7n+7) { .level-info { color: $level-color-7; } }
  
  &:nth-child(7n+1) { .level-bar { background-color: lighten($level-color-1, 20); } }
  &:nth-child(7n+2) { .level-bar { background-color: lighten($level-color-2, 20); } }
  &:nth-child(7n+3) { .level-bar { background-color: lighten($level-color-3, 20); } }
  &:nth-child(7n+4) { .level-bar { background-color: lighten($level-color-4, 20); } }
  &:nth-child(7n+5) { .level-bar { background-color: lighten($level-color-5, 20); } }
  &:nth-child(7n+6) { .level-bar { background-color: lighten($level-color-6, 20); } }
  &:nth-child(7n+7) { .level-bar { background-color: lighten($level-color-7, 20); } }
  
  &:nth-child(7n+1) { .level-progress { background-color: $level-color-1; color: findColorInvert($level-color-1); } }
  &:nth-child(7n+2) { .level-progress { background-color: $level-color-2; color: findColorInvert($level-color-2); } }
  &:nth-child(7n+3) { .level-progress { background-color: $level-color-3; color: findColorInvert($level-color-3); } }
  &:nth-child(7n+4) { .level-progress { background-color: $level-color-4; color: findColorInvert($level-color-4); } }
  &:nth-child(7n+5) { .level-progress { background-color: $level-color-5; color: findColorInvert($level-color-5); } }
  &:nth-child(7n+6) { .level-progress { background-color: $level-color-6; color: findColorInvert($level-color-6); } }
  &:nth-child(7n+7) { .level-progress { background-color: $level-color-7; color: findColorInvert($level-color-7); } }

}

.level-item + .level-item {
  margin-left: 5px;
}

.level-bar {
  position: relative;
  margin-top: .5rem;
  display: block;
  width: 100%;
  height: 20px;
  // border-radius: 6px;
}

.level-progress {
  height: 100%;
  // border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 40px;
  color: findColorInvert($orange);
  font-size: 12px;
  padding: 2px 0;
}

// .level-bar {
//   display: inline-block;
//   height: 10px;

//   &:nth-child(7n+1) { background-color: $level-color-1; }
//   &:nth-child(7n+2) { background-color: $level-color-2; }
//   &:nth-child(7n+3) { background-color: $level-color-3; }
//   &:nth-child(7n+4) { background-color: $level-color-4; }
//   &:nth-child(7n+5) { background-color: $level-color-5; }
//   &:nth-child(7n+6) { background-color: $level-color-6; }
//   &:nth-child(7n+7) { background-color: $level-color-7; }
// }