@sm-querie: 660px;
@md-querie: 900px;
@lg-querie: 1220px;

.label {
  font-size: 14px;
}

.control.is-full-width,
.field.is-full-width {
  width: 100%;
}

.control input,
.control textarea,
.control .button {
  height: auto;
  box-shadow: none;
}

.field.has-addons input,
.field.has-addons .button {
  height: 100%;
}

.form {
  width: 100%;
}

.field.is-flat {
  // width: 400px;
  // height: 29px;
  // @media(max-width: $tablet) {
  width: 100%;
  // }
}

.field.has-addons .is-flat input,
.field.is-flat input,
.field.is-flat .file-name,
.field.is-flat .file-cta,
.field.has-addons .is-flat .button {
  font-size: 14px;
  padding: 0.15rem 0.8rem 0.25rem;
  height: 29px;
  // padding: .25rem .8rem .15rem;
}

.field.is-flat .file-cta,
.field.has-addons .is-flat .button {
  padding: 0.2rem 0.8rem;
  height: 29px;
}

.is-half-width {
  max-width: 200px;
}

.has-addons .control:first-child {
  flex: 1;
}

.field.is-horizontal .field-label {
  margin-right: 0.5rem;
}

.is-horizontal.has-label-right {
  align-items: center;
  .label {
    order: 2;
    margin-bottom: 0;
    margin-left: 7px;
  }
}

.file {
  max-width: 100%;
  input[type='file'] {
    visibility: hidden;
  }
}

.file-label {
  width: 100%;
}

.file-name {
  max-width: 100%;
  width: 100%;

  border-color: $border;
  border-style: solid;
  border-width: 1px 1px 1px 0;
}

.file-input-wrapper {
  cursor: pointer;
  position: relative;
  input[type='file'] {
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }
}

.file-input-wrapper-content {
}

.file-input-wrapper-label {
  text-align: center;
  margin-top: 0.4em;
  color: #7f8996;
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.input,
.textarea,
.select select {
  border-color: #b5b5b5;
  box-shadow: none;
  height: auto;
}

.input:focus,
.input.is-focused,
.input:active,
.input.is-active,
.textarea:focus,
.textarea.is-focused,
.textarea:active,
.textarea.is-active,
.select select:focus,
.select select.is-focused,
.select select:active {
  border-color: #b5b5b5;
  box-shadow: none;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon,
.select:not(.is-multiple):not(.is-loading)::after,
.field.has-addons .control .input:hover,
.pagination-select:not(.is-multiple):not(.is-loading)::after {
  z-index: 0;
}

.field.is-horizontal .label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-right: 6px;
  > span {
    margin-left: 2px;
  }
}

.tooltip .icon i {
  // margin-top: 1px;
}

.field.is-flat .control.is-loading::after {
  top: 7px;
}

.container-register-form {
  padding: 30px 20px;
  border-radius: 5px;
  background-color: #ffffff;
  @media (min-width: 768px) {
    width: 80%;
    padding: 50px;
    &.isNarrow {
      padding: 30px 50px 50px 50px;
    }
  }
  @media (min-width: 1200px) {
    max-width: 820px;
  }
}

.custom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    align-items: unset;
  }
}

.form-section-title {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.section-identifier {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d5dadf;
  margin-bottom: 30px;
  &.narrow {
    margin-bottom: 15px;
  }
}

.progress-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}

.progress-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
  cursor: pointer;
  &.active {
    background-color: #2091ea;
    border-color: #2091ea;
  }
  &.done {
    background-color: #2091ea;
    border-color: #2091ea;
    opacity: 0.4;
  }
  &.invalid {
    background-color: transparent;
    border-color: #de0000;
  }
  @media (min-width: 768px) {
    margin-top: 35px;
    margin-left: 35px;
    margin-right: 35px;
  }
  @media (min-width: 1200px) {
    width: 40px;
    height: 40px;
  }
}

.progress-number {
  font-size: 16px;
  font-weight: bold;
  color: #b5b5b5;
  &.active {
    color: #ffffff;
  }
  &.done {
    color: #ffffff;
  }
  &.invalid {
    color: #de0000;
  }
}

.progress-title {
  font-size: 12px;
  color: #b5b5b5;
  margin-bottom: 20px;
  &.active {
    color: #2091ea;
  }
  &.invalid {
    color: #de0000;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.container-guarantor-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 820px;
}

.close-button-right {
  display: flex;
  justify-content: flex-end;
}

.container-whatsapp {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.whatsapp-button:link {
  color: #7f8996;
}

.whatsapp-button:hover {
  color: #3273dc;
}
