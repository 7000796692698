@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Bai+Jamjuree:400,400i,700');

$font-primary: 'Bai Jamjuree', sans-serif;

$max-width: 1260px;

$mq-xs: 470px;
$mq-sm: 660px;
$mq-md: 900px;
$mq-lg: 1260px;
$mq-xl: 1680px;

$primary: #28567e;
$orange: #D66200;
$gray-a: #F2F2F2;
$gray-b: #505050;
$gray-c: #F7F7F7;
$gray-d: #707070;
$gray-e: #E3E3E3;
$gray-f: #EAEAEA;
$gray-g: #666666;
$gray-h: #333333;
$green-l: #00DD90;
$white: #FFFFFF;
$black: #000000;

// primary colors
$c-p1: #263B5E;
$c-p2: #365487;
$c-p3: #4A6593;
$c-p4: #9AA9C3;
$c-p5: #C2CBDB;
$c-p6: #F8F9FB;
// gray colors
$c-g1: #000000;
$c-g2: #333333;
$c-g3: #666666;
$c-g4: #999999;
$c-g5: #B5B5B5;
$c-g6: #D7D7D7;
$c-g7: #EAEAEA;
$c-g8: #FFFFFF;
$c-g9: #FBFBFB;
$c-g10: #F5F5F5;

// opacity colors
$c-o1: rgba(0,0,0,0.1);
$c-o2: rgba(0,0,0,0.2);
$c-o3: rgba(0,0,0,0.3);
$c-o4: rgba(0,0,0,0.4);
$c-o5: rgba(0,0,0,0.5);
$c-o6: rgba(0,0,0,0.6);
$c-o7: rgba(0,0,0,0.7);
$c-o8: rgba(0,0,0,0.8);
$c-o9: rgba(0,0,0,0.9);
// warning colors
$c-w1: #EA504C;
$c-w1: #60C780;

@mixin a($n) {
	@include w($n);
	@include h($n);
}
@mixin c($n) {
	@include px(20px);
	@include w(calc(#{$n} - 40px));
}
@mixin cm($n) {
	@include mx(20px);
	@include w(calc(#{$n} - 40px));
}
@mixin w($n) { width: $n; }
@mixin h($n) { height: $n; }
@mixin mw($n) { max-width: $n; }
@mixin mh($n) { max-height: $n; }
@mixin miw($n) { min-width: $n; }
@mixin mih($n) { min-height: $n; }
@mixin m($n) {
	@include mx($n);
	@include my($n);
}
@mixin mx($n) {
	@include ml($n);
	@include mr($n);
}
@mixin my($n) {
	@include mt($n);
	@include mb($n);
}
@mixin mt($n) { margin-top: $n; }
@mixin mb($n) { margin-bottom: $n; }
@mixin ml($n) { margin-left: $n; }
@mixin mr($n) { margin-right: $n; }
@mixin p($n) {
	@include px($n);
	@include py($n);
}
@mixin px($n) {
	@include pl($n);
	@include pr($n);
}
@mixin py($n) {
	@include pt($n);
	@include pb($n);
}
@mixin pt($n) { padding-top: $n; }
@mixin pb($n) { padding-bottom: $n; }
@mixin pl($n) { padding-left: $n; }
@mixin pr($n) { padding-right: $n; }

@mixin fc($n) { color: $n; }
@mixin ff($n) { font-family: $n; }
@mixin fs($n) { font-size: $n; }
@mixin ft($n) { font-style: $n; }
@mixin fw($n) { font-weight: $n; }
@mixin lh($n) { line-height: $n; }
@mixin ls($n) { letter-spacing: $n; }

@mixin bg($n) { background-color: $n; }
@mixin td($n) { text-decoration: $n; }
@mixin ta($n) { text-align: $n; }

@mixin r($n) { border-radius: $n; }
@mixin bc($n) { border-color: $n; }
@mixin b($n) {
	@include bx($n);
	@include by($n);
}
@mixin bx($n) {
	@include bl($n);
	@include br($n);
}
@mixin by($n) {
	@include bt($n);
	@include bb($n);
}
@mixin bt($n) { border-top: $n; }
@mixin bb($n) { border-bottom: $n; }
@mixin bl($n) { border-left: $n; }
@mixin br($n) { border-right: $n; }

@mixin fx($n, $c) {
  display: flex;
  flex-direction: $n;
  align-items: $c;
}
@mixin fg($n) { flex-grow: $n }
@mixin ai($n) { align-items: $n; }
@mixin jc($n) { justify-content: $n; }

@mixin bs($x, $y, $b, $c) { box-shadow: $x $y $b $c; }

%font-primary { font-family: 'Roboto', sans-serif; }
%scrolly { overflow-y: scroll; }
%scrollx { overflow-x: scroll; }
%strong { font-weight: bold; }


%HoverPrimary {
	cursor: pointer;
	opacity: 0.8;
	&:hover {
		opacity: 1;
		p {
			color: white;
		}
	}
}


%pointer {
	cursor: pointer;
}

.max-width {
	@include w(100%);
	@include mw($max-width);
}
