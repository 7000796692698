:root {
  --condition-color: rgba(255, 230, 0, 0.6);
  --list-color: rgba(0, 132, 255, 0.25);
  --token-color: rgba(145, 255, 0, 0.3);
}

.editor-field {
  .editor-wrapper {
    position: relative;
    height: 150px;

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      max-height: 150px;
      overflow: scroll;
    }
  }

  .quill {
    position: relative;
    height: 150px;
  }
}

.operation-blot,
.merge-blot {
  display: inline;
  cursor: not-allowed;
  padding: 0;
  margin: 0;
}

.operation-blot {
  &[data-type='list'] {
    background-color: var(--list-color);
  }

  &[data-type='conditional'] {
    background-color: var(--condition-color);
  }
}

.merge-blot {
  background-color: var(--token-color);
  position: relative;
}
