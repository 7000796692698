.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    input {
        opacity: 0;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }
    input:checked~.checkbox-token {
        background-color: $dark-blue;
        border: 1px solid $dark-blue;
        &:after {
            opacity: 1;
        }
    }
}

.checkbox-token {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: rgba($dark-blue, 0);
    border-radius: $radius;
    border: 1px solid #b5b5b5;
    vertical-align: middle;
    // transition: .4s;
    &:after {
        content: "";
        width: 12px;
        height: 12px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgaGVpZ2h0PSIyNHB4IiBpZD0iTGF5ZXJfMSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNHB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iJiMxMDsgICAgZmlsbDogI2ZmZjsmIzEwOyI+PHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjEuNjUyLDMuMjExYy0wLjI5My0wLjI5NS0wLjc3LTAuMjk1LTEuMDYxLDBMOS40MSwxNC4zNCAgYy0wLjI5MywwLjI5Ny0wLjc3MSwwLjI5Ny0xLjA2MiwwTDMuNDQ5LDkuMzUxQzMuMzA0LDkuMjAzLDMuMTE0LDkuMTMsMi45MjMsOS4xMjlDMi43Myw5LjEyOCwyLjUzNCw5LjIwMSwyLjM4Nyw5LjM1MSAgbC0yLjE2NSwxLjk0NkMwLjA3OCwxMS40NDUsMCwxMS42MywwLDExLjgyM2MwLDAuMTk0LDAuMDc4LDAuMzk3LDAuMjIzLDAuNTQ0bDQuOTQsNS4xODRjMC4yOTIsMC4yOTYsMC43NzEsMC43NzYsMS4wNjIsMS4wNyAgbDIuMTI0LDIuMTQxYzAuMjkyLDAuMjkzLDAuNzY5LDAuMjkzLDEuMDYyLDBsMTQuMzY2LTE0LjM0YzAuMjkzLTAuMjk0LDAuMjkzLTAuNzc3LDAtMS4wNzFMMjEuNjUyLDMuMjExeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
        background-position: center;
        background-size: 12px;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }
}

.checkbox-description {
    display: inline-block;
    vertical-align: middle;
    color: #7F8996;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -2px;
    flex-basis: calc(100% - 25px);
}

.checkbox-token+.checkbox-description,
.checkbox-description+.checkbox-token {
    margin-left: .3rem;
}

.is-disabled {
    .checkbox-token {
        opacity: .4;
    }
}
