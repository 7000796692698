.buttons:last-child {
  margin-bottom: 0;
  .button {
    margin-bottom: 0;
  }
}

.buttons {
  flex-wrap: nowrap;
}

.button {
  transition: 0.05s ease-in-out;
  font-weight: 600;
  height: auto;
  border: 0;
  font-size: 1rem;
  padding-bottom: calc(0.438rem - 1px);
  padding-top: calc(0.438rem - 1px);
  position: relative;

  transition: opacity 0.2s ease;

  &:hover,
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
  &:hover {
    opacity: 0.92;
  }
}

.buttons .button .icon {
  height: 29.38px;
}

.button:not(.is-bare):not(.is-link):not(.is-neutral):not(.is-transparent) {
  .icon {
    z-index: 1;
  }
}

.button[disabled] {
  background-color: rgb(163, 163, 163);
  color: #fff!important;
  opacity: 1;
}

.button.is-medium {
  font-size: 0.85rem;
}

.button.is-flat {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.3;
  border-radius: 4px;
  border: 0;
  padding: 0.45rem 0.6rem;
  height: 29px;
  .icon {
    margin-top: -0.45rem;
    margin-bottom: -0.46rem;
  }
}

.button.is-bare {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: $brand-color-black;
  font-weight: 400;
  text-align: left;
  justify-content: left;
  &:hover,
  &:focus {
    background-color: transparent;
    color: $brand-color-grey;
    outline: 0;
    box-shadow: none !important;
  }
}

.button.is-full-width {
  width: 100% !important;
  // display: block;
}

.button.is-neutral {
  background-color: $white-ter;
  color: $brand-color-black;
  border: 0;
  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &:hover {
    background-color: transparent;
    color: $brand-color-grey;
    outline: 0;
    box-shadow: none !important;
  }
}

.button.is-inverted {
  &:hover,
  &:focus {
    background-color: transparent;
    color: rgba($white, 0.6);
  }
}

.button.is-featured {
  background-color: $dark-blue;
  border-color: $dark-blue;
}

.button.is-secondary {
  background-color: rgba($dark-blue, 0.6);
  border-color: $white-ter;
  color: $white;
}

.button.is-rounded {
  border-radius: 100%;
  display: block;
  border-style: inset;
  border-width: 0;
}

.button.is-link {
  @extend .link;
  padding-left: 0;
  padding-right: 0;
  &:hover,
  &:active {
    background-color: transparent;
  }
  &:focus {
    color: $primary;
  }
}

.button.is-success {
  &:hover {
    background-color: lighten($success, 10);
  }
}

.button.is-wide {
  max-width: 280px;
  width: 100%;
}

.button .icon:first-child:not(:last-child) {
  // margin-left: 0;
  // justify-content: left;
  margin: 0;
}

.button.has-icon-only {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.is-tab {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.3;
  border-radius: 4px;
  border: 0;
  padding: 0.45rem 0.6rem;
  height: 29px;
  .icon {
    margin-top: -0.45rem;
    margin-bottom: -0.46rem;
  }
  background-color: rgba($dark-blue, 0.2);
  color: $dark-blue;
  &.is-selected {
    background-color: $dark-blue;
    color: $white;
  }
  &:hover {
    background-color: $dark-blue;
    color: $white;
  }
  &:focus:not(.is-selected) {
    background-color: rgba($dark-blue, 0.2);
    color: $dark-blue;
  }
}

.button.is-large {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1.2rem;
}

.button.is-transparent {
  background-color: transparent;
  &:hover {
    // background-color: rgba($white, .2);
  }
}

// Izee theme
[data-spec-selector='auth'] {
  .button {
    background-color: $primary-izee;
    &:hover,
    &:focus {
      background-color: $primary-dark-izee;
    }
  }
  .button.is-link {
    color: $primary-izee;
    &:hover,
    &:focus {
      color: $primary-light-izee;
      background-color: transparent;
    }
  }
}

.button.is-disabled,
.button:disabled {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.6);
  }
}

.button-fend {
  display: flex;
  justify-content: flex-end;
}
