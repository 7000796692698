.flexbar {
    display: flex;
    justify-content: space-between;
    // z-index: 10;
    @media(min-width: $mobile) {
        min-height: 60px;
    }
    &.has-shadow {
        box-shadow: 0px 1px 1px rgba(#000, .2);
    }
    &.is-padded {
        padding: 1.2rem 2rem 1.05rem;
    }
    &.is-large {
        min-height: 50px;
        max-height: 50px;
    }
    &.is-responsive {
        @media(max-width: $mobile) {
            flex-direction: column;
        }
    }
}

.flexbar.is-secondary {
    // height: 68px;
    background-color: #f2f6fa;
    box-shadow: 0px 1px 1px rgba(#000, .2);
    padding: 0rem 2rem 0rem;
    .flexbar-child {
        padding: 1rem 0;
    }
    @media(max-width: $desktop) {
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
        .flexbar-child {
            overflow-x: scroll;
            +.flexbar-child {
                padding-top: 0;
            }
        }
    }
    .flexbar-child+.flexbar-child {
        margin-left: 0;
    }
}

.flexbar.buttons-proposal {
    .flexbar-child {
        padding: 1rem 0;
    }
    @media(max-width: $desktop) {
        flex-direction: column;
        padding-left: 0rem;
        padding-right: 0rem;
        .flexbar-child {
            overflow-x: scroll;
            +.flexbar-child {
                padding-top: 0;
            }
        }
    }
    .flexbar-child+.flexbar-child {
        margin-left: 0;
    }
}

.flexbar-child {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    >*+* {
        margin-left: 1rem;
    }
}

.flexbar-child+.flexbar-child {
    margin-left: 1rem;
}

.flexbar-tabs {}

// Behavior
.flexbar.is-equalized .flexbar-child {
    flex: 1;
    margin-left: 0;
}

.flexbar-child.is-shrink {
    flex-shrink: 1;
}

.flexbar-child.is-grow {
    flex-grow: 1;
}

// Alignment
.flexbar.is-aligned-right,
.flexbar-child.is-aligned-right {
    justify-content: flex-end;
}

.flexbar.is-aligned-left,
.flexbar-child.is-aligned-left {
    justify-content: flex-start;
}

.flexbar.is-aligned-center,
.flexbar-child.is-aligned-center {
    justify-content: center;
}