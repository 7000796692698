.text {
  // color: $dark-blue;
  font-size: .8rem;
}

.text.is-small {
  font-size: .7rem;
}


.text-truncate {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
