.assistant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assistant-icon {
  img {
    max-width: 110px;
  }
}

.assistant-speech-bubble {
	position: relative;
	background-color: #F2F2F2;
  border-radius: .4em;
  padding: 10px 15px;
  margin-top: 20px;
  font-size: 14px;
}

.assistant-speech-bubble.is-top:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: #F2F2F2;
	border-bottom: 0;
	margin-left: -20px;
	margin-bottom: -15px;
}

.assistant-speech-bubble.is-bottom:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-bottom-color: #F2F2F2;
	border-top: 0;
  margin-left: -20px;
	margin-top: -15px;
}

.assistant-speech-bubble.is-left:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-right-color: #F2F2F2;
	border-left: 0;
	margin-top: -20px;
	margin-left: -15px;
}
