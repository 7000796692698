.splash-screen {
  width: 0;
  height: 0;
  top: -10000px;
  left: -10000px;
  position: absolute;
  z-index: 9999999999;
  opacity: 0;
  transition: all 0s ease-in-out .300s, opacity .300s ease-in-out 0s;
  background-color: $white;
}

.splash-screen.is-visible {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .300s ease-in-out 0s;
}

.splash-screen.has-spinner:before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  display: inline-block;
  border-radius: 100%;
  border-top: 3px solid $primary;
  border-bottom: 3px solid $primary;
  border-left: 3px solid rgba(#FFF, 1);
  border-right: 3px solid rgba(#FFF, 1);
  animation: spin .3s linear infinite, fade 0.2s ease-out;
}

.splash-screen.has-spinner:after {
  content: "";
  width: 26px;
  height: 31px;
  position: absolute;
  z-index: 9999;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("~/media/logo-izee.png");
  background-size: 26px;
  background-repeat: no-repeat;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
