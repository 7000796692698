$tabs-border-bottom-color: $border;
$tabs-border-bottom-style: solid;
$tabs-border-bottom-width: 1px;
$tabs-link-color: $text;
$tabs-link-hover-border-bottom-color: $text-strong;
$tabs-link-hover-color: $text-strong;
$tabs-link-active-border-bottom-color: $link;
$tabs-link-active-color: $link;
$tabs-link-padding: 0.5em 1em;

$tabs-boxed-link-radius: $radius;
$tabs-boxed-link-hover-background-color: $background;
$tabs-boxed-link-hover-border-bottom-color: $border;

$tabs-boxed-link-active-background-color: $white;
$tabs-boxed-link-active-border-color: $border;
$tabs-boxed-link-active-border-bottom-color: transparent;

$tabs-toggle-link-border-color: $border;
$tabs-toggle-link-border-style: solid;
$tabs-toggle-link-border-width: 1px;
$tabs-toggle-link-hover-background-color: $background;
$tabs-toggle-link-hover-border-color: $border-hover;
$tabs-toggle-link-radius: $radius;
$tabs-toggle-link-active-background-color: $link;
$tabs-toggle-link-active-border-color: $link;
$tabs-toggle-link-active-color: $link-invert;
