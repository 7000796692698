.company-card {
  background-color: #f2f6fa;
  padding: 8px 16px 20px;
  display: flex;
  flex-direction: column;
  margin-top: -14px;
  margin-bottom: -14px;
}

.company-card-header {
  padding: 12px 0;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  color: $brand-color-black;

  align-items: flex-start;
  line-height: 1.2;
  .icon {
    flex: 0;
    margin-right: .45rem;
    margin-top: -4px;
  }
}

.company-card-actions {
  display: flex;
  flex-direction: row;
}
