.column > .card-select {
  height: 100%;
}

.card-select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: $radius;
  border: 1px solid $border;
  box-shadow: 0 2px 3px rgba($black, 0.1);
  transition: .4s;

  &.is-active {
    border-color: rgba($dark-blue,.5);

    .card-select-label {
      opacity: 1;
      z-index: 10;
    }

    .card-select-footer, .card-select-content {
      border-color: rgba($dark-blue,.5);
    }
  }

  &:hover {
    border-color: rgba($dark-blue,.5);

    .card-select-footer, .card-select-content {
      border-color: rgba($dark-blue,.5);
    }
  }
}

.card-select-header {
  padding: 1rem;

  .media {
    align-items: flex-start;
  }
}

.card-select-content {
  position: relative;
  display: block;
  padding: 1rem;
  transition: .4s;
}

.card-select-footer {
  position: relative;
  display: block;
  padding: .8rem 1rem;
  text-align: right;
  display: flex;
  align-items: center;
  margin-top: auto;
  transition: .4s;
  z-index: -1;

  .buttons .button {
    @media(max-width: $mobile) {
      span:not(.icon) {
        display: none;
      }

      .icon {
        margin: 0;
      }
    } 
  }
}

.card-select-label {
  margin-right: auto;
  // background-color: rgba($dark-blue,.5);
  color: $success;
  font-size: 12px;
  font-weight: 600;
  border-radius: $radius;
  line-height: 13px;
  // padding: 5px 8px;
  opacity: 0;
  transition: .4s;

  i {
    margin-right: 4px;
  }
}

.card-select-header + .card-select-content,
.card-select-header + .card-select-footer,
.card-select-content + .card-select-footer {
  border-top: 1px solid $border;
}

