.icon {
  font-size: 1rem;
}

.icon.is-small {
  font-size: .85rem;
}

.icon.is-info {
  color: $info;
}

.icon.is-danger {
  color: $danger;
}
