.loading-container {
  position: relative;
  height: 100%;
  &:before {
    content: "";
    width: 0;
    height: 0;
    top: -10000;
    left: -10000;
    position: absolute;
    z-index: 1;
    opacity: .1;
    // transition: all 0s ease-in-out .4s, opacity .4s ease-in-out 0s;
    background-color: $white;
  }
}

.loading-container.is-visible {
  &:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    // transition: opacity .4s ease-in-out 0s;
  }
}
