html {
  overflow: hidden;
}

.layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.layout.has-neutral-background {
  background-color: #f2f6fa;
}

.layout.is-vh-centered {
  justify-content: center;
  align-items: center;
}

.layout-header {
  width: 100%;
  position: relative;
  //z-index: 80;
  background-color: $primary;
  color: $white;
  padding: .5rem 2rem .5rem 2rem;

  @media(max-width: $desktop) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.layout-body {
  min-height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.layout-aside {
  flex-grow: 0;
  flex-shrink: 1;
  height: 100%;
}

.layout-main {
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.layout-footer {
  position: relative;
}
