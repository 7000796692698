@import "base";

.an-container-buttons {
	@include fx(column, flex-end);
	@include p(0px);
	@include my(30px);
	@include bg(transparent !important);
}
.an-button {
	@include bg(rgba(0,0,0,0.3));
}
.is-green {
	@include bg(#39c357);
}
.is-silver {
	@include bg(#7f8996);
}
.circle {
	@include a(8px);
	@include r(4px);
	@media(min-width: 720px) {
		@include a(10px);
		@include r(5px);
	}
	&.green {
		@include bg(rgba(0,160,0,0.8));
	}
	&.blue {
		@include bg(rgba(0,0,160,0.8));
	}
}

.image-identifier {
	@include w(140px);
	@include mb(20px);
}
.table-analysis {
	@include w(100%);
	.table-row {
		@include w(100%);
		@include fx(row, center);
		&:nth-child(odd) {
			@include bg(rgba(0,0,0,0.04));
		}
		.cell {
			@include fs(13px);
			@include m(0);
			@include px(15px);
			@include py(10px);
			@media(min-width: 720px) {
				@include fs(15px);
			}
			&.label {
				@include w(45%);
			}
			&.status {
				@include w(30px);
			}
			&.value {
				@include w(calc(55% - 30px));
			}
		}
	}
	&.grid {
		@include b(1px solid rgba(0,0,0,0.1));
		@include fx(column, flex-start);
		@include py(10px);
		@include mb(30px);
		@include w(100%);
		.table-row {
			@include bb(1px solid rgba(0,0,0,0.1));
			@include mx(10px);
			@include w(calc(100% - 20px));
			&:nth-child(odd) {
				@include bg(transparent);
			}
			&:last-child() {
				@include bb(none);
			}
			.cell {
				@include w(100%);
				@include px(0px);
				&.value {
					@include fw(bold);
				}
				&.green {
					@include fc(rgba(0,160,0,0.8));
				}
				&.blue {
					@include fc(rgba(0,0,160,0.8));
				}
				&.label {
					@include fs(13px);
				}
				&.status {
					display: none;
				}
			}
		}
		@media(min-width: 720px) {
			@include fx(row, flex-start);
			@include jc(space-evenly);
			.table-row {
				@include fx(column, center);
				@include m(0);
				@include bb(none);
				@include br(1px solid rgba(0,0,0,0.1));
				&:last-child() {
					@include br(none);
				}
				.cell {
					@include py(5px);
					@include ta(center);
					&.value {
						@include pt(0);
					}
				}
			}
		}
	}
}

.an-requested-table {
	@include fs(13px);
	@include py(10px);
	@include mb(20px);
	@media(min-width: 720px) {
		@include fx(row, flex-start);
		@include jc(space-evenly);
		@include py(20px);
	}
	.item-line {
		@include bb(1px solid rgba(0,0,0,0.1));
		@include py(15px);
		@include w(100%);
		&:last-child {
			@include bb(none);
		}
		&.right {
			align-self: center;
			text-align: right;
		}
		.in-row {
			@include fx(row, center);
			@include jc(flex-start);
			.circle {
				@include mr(10px);
			}
		}
		@media(min-width: 720px) {
			@include py(0px);
			@include bb(none);
		}
	}
}
