// ==========================================================================
// .table
// ==========================================================================
$border-radius-default: $radius;
$border-size-small: 1px;
$color-base: $dark-blue;
$color-light: #f2f2f2;
$size-xxxsmall: .5rem;
$size-xxsmall: .5rem;
$size-xsmall: .6rem;
$size-small: 1rem;
$size-large: 1rem;
.datatable {
    border-spacing: 0;
    width: 100%;
    border-radius: $border-radius-default;
    // overflow: hidden;
    tr:focus {
        outline: none;
    }
}

.datatable-footer th {
    background-color: $white !important;
}

.datatable-cell {
    text-align: left;
    padding: .750rem 1rem .8rem;
    border-top: $border-size-small solid rgba($color-base, .2);
    border-left: $border-size-small solid rgba($color-base, .2);
    vertical-align: middle;
    >* {
        vertical-align: middle;
    }
    &:first-child {
        border-left: 0;
    }
}

th.datatable-cell {
    white-space: nowrap;
}

.datatable-body .datatable-cell {
    font-size: .850rem;
}

.datatable-cell.is-multiline {
    white-space: normal;
}

.datatable-body .datatable-row:first-child .datatable-cell {
    border-top: 0;
}

.datatable-head {
    background-color: $color-base;
    font-size: .950rem;
}

.datatable-head .datatable-cell {
    border-top: 0;
    color: $white;
    fill: $white;
    height: 48px;
    // font-size: $size-default;
    // font-family: $font-family-base;
    // font-weight: $font-weight-semibold;
    border-left: $border-size-small solid rgba($white, .2);
}

.datatable-head .datatable-cell.datatable-cell--sortable {
    position: relative;
    cursor: pointer;
    &:before {
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        right: $size-small;
        transform: translateY(-100%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: calc(50% - 2px);
        right: $size-small;
        transform: translateY(100%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: transparent;
    }
}

.datatable-head .datatable-cell.datatable-cell--sordatatable.is-ascending {
    position: relative;
    &:before {
        border-color: transparent transparent $white transparent;
    }
    &:after {
        border-color: rgba($white, .6) transparent transparent transparent;
    }
}

.datatable-head .datatable-cell.datatable-cell--sordatatable.is-descending {
    position: relative;
    &:before {
        border-color: transparent transparent rgba($white, .6) transparent;
    }
    &:after {
        border-color: $white transparent transparent transparent;
    }
}

// .datatable-body
.datatable-body .datatable-row {
    transition: all .4s ease-in-out;
    &.has-hover:hover {
        background-color: $white-ter;
    }
    &:last-child .datatable-cell {
        border-bottom: $border-size-small solid rgba($color-base, .2);
    }
}

.datatable-body .datatable-row.is-selected {
    background-color: rgba($color-base, .2);
}

// .datatable-footer
.datatable-footer .datatable-row .datatable-cell {
    // background-color: rgba(#f2f6fa, 1);
    border: none;
    padding: 2rem 1rem;
    // border-bottom: $border-size-small solid rgba($color-base, .2);
    @media(max-width: $desktop) {
        padding: 1.5rem 1rem;
    }
}

// Sizes
.datatable.datatable--condensed .datatable-body .datatable-cell {
    padding: $size-xxxsmall $size-small;
}

.datatable.datatable--small .datatable-cell {
    padding: $size-xxsmall $size-small;
}

.datatable.datatable--large .datatable-cell {
    padding: $size-large $size-small;
}

// Alignment
.datatable-cell.datatable-cell--align-right {
    text-align: right;
}

.datatable-cell.datatable-cell--align-center {
    text-align: center;
}

// Globals
.datatable+.datatable {
    margin-top: $size-small;
}

.datatable.datatable--row-lines .datatable-cell {
    border-left: 0;
}

.datatable.datatable--borderless .datatable-head .datatable-cell,
.datatable.datatable--borderless .datatable-body .datatable-cell {
    border-color: transparent;
}

.datatable.datatable--borderless .datatable-body .datatable-row {
    &:last-child .datatable-cell {
        border-bottom: $border-size-small solid rgba($color-base, .2);
    }
}

.datatable.datatable--stripped .datatable-row:nth-of-type(even) {
    background-color: $color-light;
}

.datatable-cell.datatable-cell--shrink {
    width: 1px;
    white-space: nowrap;
}

// .table-container
.datatable-container {
    overflow-x: auto;
}

.datatable-container+.datatable-container,
.datatable+.datatable-container,
.datatable-container+.datatable {
    margin-top: $size-small;
}

// Datatable mobile
.datatable {
    @media(max-width: $desktop) {
        display: block;
        overflow: visible;
    }
}

.datatable-body {
    @media(max-width: $desktop) {
        display: block;
    }
}

.datatable-head {
    @media(max-width: $desktop) {
        display: none;
    }
}

.datatable-cell {
    position: relative;
    &:before {
        content: attr(data-label);
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        color: $grey;
        text-transform: uppercase;
        display: block;
        position: absolute;
        top: .5rem;
        left: .5rem;
        opacity: 0;
        overflow: hidden;
    }
    @media(max-width: $desktop) {
        padding: 1.5rem .5rem .5rem .5rem;
        display: block;
        border: 1px solid $white-ter !important;
        text-align: left !important;
        width: 100%;
        &:before {
            opacity: 1;
        }
        +.datatable-cell {
            border-top: none !important;
        }
        &:first-child:not(.mock-text) {
            text-align: right !important;
            padding-top: 6px;
            padding-left: 70px;
            border-bottom-color: #e2e2e2 !important;
        }
        &:last-child:not(.mock-text) {
            text-align: right !important;
            padding-top: 6px;
            padding-left: 70px;
            border-top: 1px solid #e2e2e2 !important;
        }
    }
}

.datatable-body .datatable-row {
    @media(max-width: $desktop) {
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        overflow: hidden;
        display: block;
        background-color: $white;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .05);
        &.has-hover:hover {
            background-color: $white;
        }
        &:hover {
            background-color: transparent;
        }
        +.datatable-row {
            margin-top: 1rem;
        }
    }
}

.datatable-footer {
    @media(max-width: $desktop) {
        display: block;
        .datatable-row {
            display: block;
        }
        .datatable-cell {
            border: none !important;
            display: block;
        }
    }
}