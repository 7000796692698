.form-grid {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.form-grid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 14px;
  width: 100% !important;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 0;
  }
}

.form-grid-column {
  @media (min-width: 768px) {
    flex-direction: column;
    width: calc(50% - 20px);
  }
}

.form-grid-fcontainer {
  padding-top: 10px;
  padding-bottom: 10px;

  @media (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.form-grid-fcontainer-full-width {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.container-mobile {
  display: flex;
}

.search-label-mobile {
  display: flex;
  flex-grow: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.search-button-mobile {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
