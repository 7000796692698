
.menu-list li {
  margin-left: -16px;
  margin-right: -16px;
  padding: 8px 18px;
  &:hover {
    background-color: $white-ter;
  }
}

.menu-list li button,
.menu-list li a, {
  color: $brand-color-black;
  font-size: 14px;
  font-weight: 400;
  .icon {
    font-size: 1rem;
    color: $brand-color-grey;
  }
}
