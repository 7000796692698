.media {
  align-items: center;
}

.media-left {
  margin-right: .5rem;
}

// .media-content {
//   padding-top: 3px;
// }
