// Reset the appearance
@mixin appearance-reset {
 -webkit-appearance: none;
    -moz-appearance: none;
     -ms-appearance: none;
         appearance: none;
 background: none;
 border-radius: 0;
 border: none;
 line-height: 1;
 &:focus { outline: none; }
}

// Reset button and a with button behavior
@mixin button-reset {
 @include appearance-reset;
 display: inline-block;
 cursor: pointer;
 text-align: center;
 text-decoration: none !important;
}