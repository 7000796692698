.fileUploader {
    width: 100%;
}

.fileContainer {
    background: #fff;
    position: relative;
    border-radius: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    transition: all 0.3s ease-in;
}

.fileContainer input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.fileContainer p {
    font-size: 12px;
    margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
    max-width: 300px;
    font-size: 12px;
    color: red;
    text-align: left;
}

.fileContainer .chooseFileButton {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: #3f4257;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
}

.fileContainer .chooseFileButton2 {
    padding: 5px 10px;
    background: #3f4257;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
}

.fileContainer .chooseFileButton:hover {
    background: #545972;
}

.fileContainer .uploadFilesButton {
    padding: 5px 43px;
    background: transparent;
    border-radius: 30px;
    color: #3f4257;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
    background: #3f4257;
    color: #fff;
}

.fileContainer .uploadIcon {
    width: 50px;
    height: 50px;
}

.fileContainer .uploadPicturesWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.fileContainer .uploadPictureContainer {
    position: relative;
    width: 135px;
    margin: 5px;
    padding: 5px;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #d0dbe4;
    position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
    width: 100%;
}

.fileContainer .deleteImage {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #fff;
    background: #ff4081;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    width: 30px;
    height: 30px;
}

.flipMove {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}