@keyframes fade-in {
  0% {
    width: 0;
    opacity: 0;
    right: calc(100% - 0px);
  }
  1% {
    width: auto;
    opacity: 0;
    right: calc(100% - 0px);
  }
  100% {
    width: auto;
    opacity: 1;
    right: calc(100% + 5px);
  }
}

@keyframes fade-out {
  0% {
    width: auto;
    opacity: 1;
    right: calc(100% + 5px);
  }
  99% {
    width: auto;
    opacity: 0;
    right: calc(100% - 0px);
  }
  100% {
    width: 0;
    opacity: 0;
    right: calc(100% - 0px);
  }
}

@keyframes hide-in {
  0% {
    height: auto;
    overflow: visible;
  }
  100% {
    height: 0;
    overflow: hidden;
  }
}

@keyframes hide-out {
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: auto;
    overflow: visible;
  }
}

@keyframes fade-up {
  0% {
    bottom: -100px;
    opacity: 0;
  }

  50% {
    bottom: 30px;
    opacity: 1;
  }

  100% {
    bottom: 20px;
    opacity: 1;
  }
}

$context-menu-time: .2s;

/////////////////////

.context-menu {
  position: fixed;
  bottom: -100px;
  right: 2rem;
  z-index: 100;
  opacity: 0;


  &.is-active {

    > .context-menu-button {
      transform: rotate(-45deg);
    }

    .context-menu-content {
      animation: hide-out $context-menu-time forwards;

      > .context-menu-button {
        transform: scale(1);
        opacity: 1;

        .icon {
          opacity: 1;
          transition-delay: $context-menu-time * .6666;
        }
      }
    }
  }

  &.is-visible {
    animation: fade-up $context-menu-time * 2 forwards;
  }

  @media(max-width: $desktop) {
    right: 1rem;
  }
}

$button-size: 48px;

.context-menu-button {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  width: $button-size;
  height: $button-size;
  background-color: $primary;
  transition: $context-menu-time;
  border: none;
  border-radius: 100%;
  box-shadow: 0px 0px 5px rgba(0,0,0,.4);

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    i {
      color: $white;
    }
  }

  &:hover,
  &:focus {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: rgba(0, 0, 0, .2);
      z-index: 0;
      transition: .2s;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.is-featured {
    background-color: $dark-blue;

    &:after {
      content: "";
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      position: absolute;
      top: -2px;
      left: -2px;
      border: 3px solid lighten($dark-blue, 15);
      border-radius: 100%;
    }
  }
}

.context-menu-button.is-small {
  width: $button-size - 16px;
  height: $button-size - 16px;
}

.context-menu-content {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);

  height: 0;
  overflow: hidden;

  animation: hide-in $context-menu-time forwards;

  .context-menu-button {
    margin: 5px;
    transform: scale(0);
    opacity: 0;

    .icon {
      opacity: 0;
      transition: $context-menu-time;
    }

    &:hover {
      .context-menu-label {
        opacity: 1;
        animation: fade-in $context-menu-time forwards;
      }
    }
  }
}

.context-menu-label {
  position: absolute;
  top: 6px;
  right: calc(100% - 5px);

  color: $grey;
  font-size: 12px;
  white-space: nowrap;
  background-color: $white;
  border-radius: 4px;
  padding: 3px 5px;
  box-shadow: 3px 3px 5px rgba(0,0,0,.15);

  overflow: hidden;
  opacity: 0;
  width: 0;
  animation: fade-out $context-menu-time * .6666 forwards;
}

.context-menu.is-active .context-menu-content > .context-menu-button {
  &:nth-child(n+5) { transition-delay: $context-menu-time * .3333; .icon { transition-delay: $context-menu-time * 1.333; } }
  &:nth-child(4) { transition-delay: $context-menu-time * .5; .icon { transition-delay: $context-menu-time * 1.5; } }
  &:nth-child(3) { transition-delay: $context-menu-time * .6666; .icon { transition-delay: $context-menu-time * 1.6666; } }
  &:nth-child(2) { transition-delay: $context-menu-time * .75; .icon { transition-delay: $context-menu-time * 1.8333; } }
  &:nth-child(1) { transition-delay: $context-menu-time; .icon { transition-delay: $context-menu-time * 2; } }
}
