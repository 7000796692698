.radio-group {
  margin-bottom: 20px;
}

.radio-group-title {
  font-size: 14px;
  font-weight: 500;
}

.required {
  color: red;
}

.radio-options {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.radio-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.radio-input {
  margin-right: 8px;
}
