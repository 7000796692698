.pagination-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: 2rem;
  }


  @media(max-width: $desktop) {
    flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;

    > * + * {
      margin: 1rem 0 0 0;
    }
  }
}


.pagination {
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
  text-align: left;

  > * {
    border: 1px solid rgba($dark-blue, .2);
  }

  &-link {
    border: 1px solid rgba($dark-blue, .2);
    vertical-align: middle;
    font-weight: 600;
  
    &.is-current {
      background-color: $dark-blue;
      color: $white;
    }
    &:hover {
      background-color: $dark-blue;
      color: $white;
      border-color: rgba($dark-blue, .2);
    }
    @media(max-width: $tablet) {
  
      &:first-child, &:last-child {
        background-color: rgba($dark-blue, .2);
      }
    }
  }
}

.pagination .pagination-link.is-arrow {
  background-color: rgba($dark-blue, .2);
  color: $grey;
  border: none;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: none;
}

.pagination-previous,
.pagination-next {
  border: none;
  // transition: .3s;
  padding-bottom: calc(0.3em - 1px);
  border: 1px solid rgba($dark-blue, .2);

  &:hover {
    background-color: rgba($dark-blue, .2);
    border-color: rgba($dark-blue, .2);
    color: $grey;
  }
}


.pagination-select {
  @extend .select;
  width: 150px !important;
  display: none !important;
  align-items: center;

  select {
    height: auto !important;
  }

  &:after {
    border-color: $dark-blue !important;
  }

  @media(max-width: $tablet) {
    display: flex !important;
  }
}

.pagination-select-label {
  font-size: 12px;
  text-transform: uppercase;
  color: $grey;
  margin-right: .5rem;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: $dark-blue;
  // box-shadow: 0 0 0 0.125em rgba($dark-blue, 0.25);
  // border: 0;
  box-shadow: none;
}
