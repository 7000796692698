$message-background-color: $background;
$message-radius: $radius;

$message-header-background-color: $text;
$message-header-color: $text-invert;
$message-header-padding: 0.5em 0.75em;
$message-header-radius: $radius;

$message-body-border: 1px solid $border;
$message-body-color: $text;
$message-body-padding: 1em 1.25em;
$message-body-radius: $radius;

$message-body-pre-background-color: $white;
$message-body-pre-code-background-color: transparent;
